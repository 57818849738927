import type { ReploEditableElementWithId } from "@editor/utils/element";
import type { SetStateAction } from "react";
import type {
  ReploElement,
  ReploPartialElement,
} from "schemas/generated/element";

import * as React from "react";

import { updateAndSaveElement } from "@editor/actions/core-actions";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useElementValidation } from "@editor/hooks/element";
import { useDraftElementMetadata } from "@editor/hooks/useDraftElementMetadata";
import { analytics } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectElementIsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getFormattedElementName } from "@editor/utils/element";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

/**
 * This hook handles update, validation and the state of a Replo element.
 */
export function useEditableElement({
  element: initialElement,
  elementId: initialElementId,
}: {
  element?: ReploEditableElementWithId;
  elementId?: string;
}) {
  // TODO (Ben O., 02-01-2025): clean up once fully moved to trpc
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const originalElementSelector = initialElement ?? draftElement;

  const draftElementMetadata = useDraftElementMetadata();
  const { data: initialElementMetadata } =
    trpc.element.getElementMetadataById.useQuery(
      initialElement?.id ?? initialElementId ?? skipToken,
      {
        // NOTE (Ben O., 2025-02-12): Whenever we call updateElementMetadata,
        // we should be voiding this trpc call but adding this here to ensure
        // that the element metadata is refetched when the element is updated always
        refetchOnMount: true,
      },
    );

  const originalElementMetadata =
    initialElementMetadata ?? draftElementMetadata;

  const originalElement = isFeatureEnabled("refactor-element-settings")
    ? originalElementMetadata
    : originalElementSelector;

  const [element, setElement] = React.useState<
    ReploEditableElementWithId | undefined
  >(originalElement ?? undefined);

  React.useEffect(() => {
    if (!element && originalElement) {
      setElement(originalElement);
    }
  }, [element, originalElement]);

  React.useEffect(() => {
    if (
      isFeatureEnabled("refactor-element-settings") &&
      initialElementMetadata
    ) {
      setElement(initialElementMetadata);
    }
  }, [initialElementMetadata]);

  const validateElement = useElementValidation(element?.type);
  const initialIsHomepage = React.useRef(initialElement?.isHomepage ?? false);
  const dispatch = useEditorDispatch();
  const isElementLoading = useEditorSelector(selectElementIsLoading);
  const [isUpdatingElement, setIsUpdatingElement] = React.useState(false);

  const updateElementMetadata = trpc.element.updateElementMetadata.useMutation({
    onSuccess: () => {
      void trpcUtils.element.getElementMetadataById.invalidate(element?.id);
      void trpcUtils.element.listAllElementsMetadata.invalidate();
      successToast("Settings updated!");
      // TODO (Ben O., 2025-02-13): Remove this once fully over to TRPC
      void trpcUtils.element.getById.invalidate(element?.id);
    },
  });

  const onChangeElement = React.useCallback(
    (value: SetStateAction<ReploPartialElement>) => {
      setElement((prev) => {
        if (!prev) {
          return prev;
        }
        return {
          ...prev,
          ...(typeof value === "function" ? value(prev) : value),
        };
      });
    },
    [],
  );

  const onUpdateElement = React.useCallback(
    function onUpdateElementFn(element: ReploEditableElementWithId) {
      const isSettingHomepage =
        element.isHomepage && !initialIsHomepage.current;
      analytics.logEvent("element.update", {
        type: element.type,
        elementId: element.id,
      });

      return dispatch(
        updateAndSaveElement(
          element.id,
          {
            ...element,
            name: getFormattedElementName(element),
          },
          isSettingHomepage,
        ),
      );
    },
    [dispatch],
  );

  const onUpdateElementMetadata = React.useCallback(
    async function onUpdateElementMetadataFn(
      element: ReploEditableElementWithId,
    ) {
      setIsUpdatingElement(true);
      analytics.logEvent("element.update", {
        type: element.type,
        elementId: element.id,
      });

      await updateElementMetadata
        .mutateAsync({
          element: {
            ...element,
            id: element.id,
            name: getFormattedElementName(element),
          } as ReploPartialElement &
            Pick<ReploElement, "id"> &
            Omit<ReploElement, "component">,
        })
        .finally(() => {
          setIsUpdatingElement(false);
        });
    },
    [updateElementMetadata],
  );

  return {
    element: element,
    onChangeElement,
    updateElement: onUpdateElement,
    updateElementMetadata: onUpdateElementMetadata,
    validateElement,
    isUpdatingElement: isElementLoading || isUpdatingElement,
  };
}
